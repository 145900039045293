import { ContentfulClientApi, createClient } from 'contentful';
import type { NextPage } from 'next';
import Head from 'next/head';
import LandingPage from '~/components/B2BLanding/pages/LandingPage';
import { TypeIg_blogs } from '~/types/Blog';
import { IS_DEV_OR_PREVIEW_ENV, IS_LOCAL_ENV } from '~/utils/constants';

let client: ContentfulClientApi<any> = {} as ContentfulClientApi<any>;
if (
  process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID &&
  process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN_PREVIEW
) {
  client = createClient({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string,
    accessToken:
      IS_DEV_OR_PREVIEW_ENV || IS_LOCAL_ENV
        ? (process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN_PREVIEW as string)
        : (process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN_PROD as string),
    host: IS_DEV_OR_PREVIEW_ENV || IS_LOCAL_ENV ? 'preview.contentful.com' : 'cdn.contentful.com',
  });
}

export async function getStaticProps() {
  // for making this page static
  const queryObj = {
    content_type: 'ig_blogs',
  };
  const res =
    Object.keys(client).length > 0
      ? await client.getEntries<TypeIg_blogs>(queryObj)
      : { items: [] };
  return {
    props: {
      blogData: res.items.slice(0, 10).map((item) => ({
        title: (item.fields as any).title || null,
        slug: (item.fields as any).slug || null,
      })), // Adding || null so if there is an article currently being worked on, and it doesn't have all requisite info, builds do not break
    },
    revalidate: 300,
  };
}

interface LandingPageProps {
  blogData: Array<{ title: string; slug: string }>;
}

const Home: NextPage<LandingPageProps> = (props: LandingPageProps) => {
  return (
    <div className='bg-night5 hide-scrollbar'>
      <Head>
        <title>Internet Game - Free Online Games, Play With Friends!</title>
        <meta
          name='description'
          content='The easiest way to play games with friends online. Just share link, choose a game and click play.'
        />

        <link
          rel='preload'
          href='/fonts/SpaceGrotesk/space-grotesk-variable.woff2'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        {process.env.NEXT_PUBLIC_REB2B_CLIENT_ID !== '' && (
          <script
            type='text/javascript'
            dangerouslySetInnerHTML={{
              __html: `
            !function () {
              var reb2b = window.reb2b = window.reb2b || [];
              if (reb2b.invoked) return;
              reb2b.invoked = true;
              reb2b.methods = ["identify", "collect"];
              reb2b.factory = function (method) {
                return function () {
                  var args = Array.prototype.slice.call(arguments);
                  args.unshift(method);reb2b.push(args);
                  return reb2b;
                };
              };
              for (var i = 0; i < reb2b.methods.length; i++) {
                var key = reb2b.methods[i];
                reb2b[key] = reb2b.factory(key);
              }
              reb2b.load = function (key) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.async = true;
                script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
                var first = document.getElementsByTagName("script")[0];
                first.parentNode.insertBefore(script, first);
              };
              reb2b.SNIPPET_VERSION = "1.0.1";
              reb2b.load("${process.env.NEXT_PUBLIC_REB2B_CLIENT_ID}");
            }();
          `,
            }}
          />
        )}
      </Head>
      <LandingPage blogData={props.blogData} />
    </div>
  );
};

export default Home;
